import React, { Component } from "react"
import logoImg from "../images/logo.png"
import { Link } from "gatsby"
import Img from "gatsby-image"

export class Footer extends Component {
  render() {
    return (
      <section className="footer-wrapper pad-20  style-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 widget text-center mx-auto d-block">
              <Link to="/" className="brand-logo">
                <img src={logoImg} alt="brand logo" />
              </Link>

              <div className="copyright">
                Copyrights © 2020 <b>forest-lab</b>
                <br />
                All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Footer
