import React, { Component } from "react"
import Link from "gatsby-link"
import logoImg from "../images/logo.png"
import styled from "styled-components"
import media from "styled-media-query"
import ContactImg from "../images/favicon.png"
import Img from "gatsby-image"

export class Header extends Component {
  render() {
    const Wrapper = styled.div`
    ${media.greaterThan("large")`
      about {
        position: relative;
        z-index: auto;
      }
    
      .megaMenu {
        position: absolute;
        width: 960px;
        height: 200px;        
        border-radius: 10px 10px 10px 10px;
        background-color:#ffff;
        display: none;
        left: -470px;
        bottom: -210px;
        box-shadow: 10px 10px 10px rgba(0,0,0,0.2);   
      }
      .about .megaMenu:after { /* 三角形を作成 */
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: -30px; /* 三角形の高さを２倍した値 */
        left: 500px;
        border: 16px solid transparent;
        border-bottom: 20px solid #a57d33;
             
      }
      .about .text {
        position: absolute;
        font-size: 16px;
        color: #000;
      }
      .about:hover .megaMenu {
        z-index: 2;
        display: block;
      }   
      .megaMenu-header{
         background: #a57d33;
         border-radius: 10px 10px 0px 0px;
         color: white
      }

      #main-menu li{
        padding: 0 23px;
      }
      
    `}
      
  ${media.between("800px", "1024px")`
      .about {
        position: relative;
        z-index: auto;
      }
    
      .megaMenu {
        position: absolute;
        width: 900px;
        height: 200px;        
        border-radius: 10px 10px 10px 10px;
        background-color: #ffff;
        display: none;
        left: -380px;
        bottom: -210px;
        box-shadow: 10px 10px 10px rgba(0,0,0,0.2);        
      }
      .about .megaMenu:after { /* 三角形を作成 */
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: -26px; /* 三角形の高さを２倍した値 */
        left: 415px;
        border: 16px solid transparent;
        border-bottom: 16px solid #a57d33;
      }
      .about .text {
        position: absolute;
        font-size: 16px;
        color: #000;
      }
      .about:hover .megaMenu {
        z-index: 2;
        display: block;
      }   
      .megaMenu-header{
         background: #a57d33;
         border-radius: 10px 10px 0px 0px;
         color: white
      }
      #main-menu ul li {
        padding: 0 18px;
        display: inline-block;
        position: relative;
      }
  `}
      
    
    ${media.between("450px", "768px")`
      .about {
        position: relative;
        z-index: auto;
      }
    
      .megaMenu {
        position: absolute;
        width: 750px;
        height: 200px;        
        border-radius: 10px 10px 10px 10px;
        background-color: white;
        display: none;
        left: -170px;
        bottom: -210px;
        box-shadow: 10px 10px 10px rgba(0,0,0,0.2);        
      }
      .about .megaMenu:after { /* 三角形を作成 */
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: -26px; /* 三角形の高さを２倍した値 */
        left: 200px;
        border: 16px solid transparent;
        border-bottom: 16px solid #a57d33;
      }
      .about .text {
        position: absolute;
        font-size: 16px;
        color: #000;
      }
      .about:hover .megaMenu {
        z-index: 2;
        display: block;
      }   
      .megaMenu-header{
         background: #a57d33;
         border-radius: 10px 10px 0px 0px;
         color: white
      }
      #main-menu ul li {
        padding: 0 18px;
        display: inline-block;
        position: relative;
      }
  `}
      
    ${media.lessThan("small")`
       .megaMenu{
          display: none;
       }
    `}
    `

    return (
      <Wrapper>
        <section className="header-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 text-left">
                <Link to="/" className="brand-logo">
                  <img src={logoImg} alt="brand logo" />
                </Link>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-6 text-right">
                <nav id="main-menu" className="text-center main-menu">
                  <ul>
                    <li>
                      <Link to="/">HOME</Link>
                    </li>
                    <li className="about">
                      <Link to="/about/">ABOUT</Link>
                      <div className="megaMenu">
                        <div className="megaMenu-header">
                          Forest-Labについて
                        </div>
                        <div className="row">
                          <div class="col-md-3 m-auto">
                            <h2>FOREST </h2>
                            <h2>LABO</h2>
                          </div>

                          <div className="col-md-6 m-auto">
                            <ul>
                              <li>
                                <Link to="/about" class="active">
                                  団体について
                                </Link>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener"
                                  href="https://docs.google.com/forms/d/e/1FAIpQLSfNHgUjxwF4x684TThNDXgOe23Y0vCtPtbmPtFU7OcaAW3k-Q/viewform"
                                >
                                  会員になる
                                </a>
                              </li>
                              <li>
                                <Link to="/service/">事業紹介</Link>
                              </li>
                              <li>
                                <Link to="/report">活動報告</Link>
                              </li>
                              <li>
                                <Link to="/contribution">寄付する</Link>
                              </li>

                              <li>
                                <Link to="/thesis">森林浴を科学する</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <Link to="/news/">NEWS</Link>
                    </li>
                    <li>
                      <Link to="/product/">PRODUCT</Link>
                    </li>
                    <li>
                      <Link to="/blog/">BLOG</Link>
                    </li>
                    <li>
                      <Link to="/contact/">CONTACT</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="megaMenu">
            <div className="megaMenu-header"></div>
          </div>
        </section>
      </Wrapper>
    )
  }
}

export default Header
